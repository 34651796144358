<script>
import Layout from "../../layouts/main";

export default {

  components: {
    Layout
  },
  data() {
    return {
        
    }
  },
  

};
</script>

<template>
  <Layout>
    <main class="py-4">
        <div class="row mb-3">
            <div class="col">
                <h2 class="font-size-22 text-theme-dark">Notifications</h2>
            </div>
        </div>
        <div class="notify">
            <div class="card mb-2 position-relative">
                <div class="card-body p-3">
                    <div class="notification-card">
                        <p>Your application has been <strong>Submitted.</strong> We will update you once your application will be changed.</p>
                        <span class="dateTimeStyle text-muted d-flex"><i class="mdi mdi-calendar-clock me-2"></i>10.00 AM</span>
                    </div>
                </div>
            </div>
            <div class="card mb-2 position-relative">
                <div class="card-body p-3">
                    <div class="notification-card">
                        <p>Your application is under <strong>Processed.</strong> We will update you once your application will be changed</p>
                        <span class="dateTimeStyle text-muted d-flex"><i class="mdi mdi-calendar-clock me-2"></i>10.00 AM</span>
                    </div>
                </div>
            </div>
            <div class="card mb-2 position-relative">
                <div class="card-body p-3">
                    <div class="notification-card">
                        <p>Your application seems to be <strong>Incomplete.</strong> Please follow the link to update your details and re-submit your application. <a href="#" class="text-theme">https://www.gmail.com</a></p>
                        <span class="dateTimeStyle text-muted d-flex"><i class="mdi mdi-calendar-clock me-2"></i>10.00 AM</span>
                    </div>
                </div>
            </div>
            <div class="card mb-2 position-relative">
                <div class="card-body p-3">
                    <div class="notification-card">
                        <p><strong>Congratulations ,</strong> Your application has been <strong>Approved.</strong></p>
                        <span class="dateTimeStyle text-muted d-flex"><i class="mdi mdi-calendar-clock me-2"></i>10.00 AM</span>
                    </div>
                </div>
            </div>
            <div class="card mb-2 position-relative">
                <div class="card-body p-3">
                    <div class="notification-card">
                        <p><strong>Sorry,</strong>  We regret to inform you that your application has been  <strong>Rejected.</strong></p>
                        <span class="dateTimeStyle text-muted d-flex"><i class="mdi mdi-calendar-clock me-2"></i>10.00 AM</span>
                    </div>
                </div>
            </div>
        </div>
    </main>
  </Layout>
</template>