export const menuItemsStaff = [
    {
        id: 1,
        label: 'Dashboard',
        link: '/staff_dashboard',
        icon: 'bx bx-home-circle',
    },
    {
        id: 2,
        label: 'All Application',
        link: '/application_list',
        icon: 'mdi mdi-format-list-text',
    },
    {
        id: 3,
        label: 'View Log',
        link: '/staff_view_log',
        icon: 'mdi mdi-file-document-outline',
    },
    {
        id: 4,
        label: 'Notifications',
        link: '/staff_notification',
        icon: 'mdi mdi-bell-ring-outline',
    },
    {
        id: 5,
        label: 'My Profile',
        link: '/my_profile',
        icon: 'mdi mdi-account-outline',
    },
];

