export const menuItemsApplicant = [
    {
        id: 1,
        label: 'Dashboard',
        link: '/',
        icon: 'mdi mdi-home',
    },
    
    {
        id: 2,
        label: 'Application status',
        link: '/view_log',
        icon: 'mdi mdi-file-eye'
    },
    {
        id: 3,
        label: 'Notification',
        link: '/notifications',
        icon: 'mdi mdi-bell-ring-outline'
    }
];

