export const menuItemsAdmin = [
    {
        id: 1,
        label: 'Dashboard',
        link: '/admin_dashboard',
        icon: 'mdi mdi-home',
    },
    {
        id: 2,
        label: 'All Staff',
        link: '/staff_users',
        icon: 'mdi mdi-account-group-outline',
    },
    {
        id: 3,
        label: 'All Applicant',
        link: '/applicants',
        icon: 'mdi mdi-account-multiple-plus',
    },
    // {
    //     id: 4,
    //     label: 'Notifications',
    //     link: '/admin_notifications',
    //     icon: 'mdi mdi-bell-ring-outline',
    // },
];

