<script>
// import { layoutMethods } from "@/state/helpers";
import { menuItemsApplicant } from "./horizontal-menu";
import { menuItemsStaff } from "./horizontal-menu-staff";
import { menuItemsAdmin } from "./horizontal-menu-admin";

export default {
  data() {
    return {
      user: 'staff',
      menuItems: null,
    };
  },
  beforeMount() {
    // localStorage.setItem('userType', 'applicant')
    const userType = localStorage.getItem('userType')

    this.menuItems = (userType === 'applicant') ? menuItemsApplicant : ((userType === 'staff' || userType === 'verification officer') ? menuItemsStaff :menuItemsAdmin)  
  }

};
</script>
<template>
  <div class="topnav">
    <div class="container">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
        <div class="collapse navbar-collapse active" id="topnav-menu-content">
          <ul class="navbar-nav">
            <li class="nav-item dropdown" v-for="menu in menuItems" :key="menu.id">
              <template v-if="menu.subItems">
                <span class="nav-link dropdown-toggle arrow-none" @click="onMenuClick($event)" role="button">
                  <i class="me-2" :class="`${menu.icon}`"></i>
                  {{ menu.label }}
                  <div class="arrow-down"></div>
                </span>
                <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                  <router-link class="col dropdown-item side-nav-link-ref" v-for="subitem in menu.subItems"
                    :key="subitem.id" :to="`${subitem.link}`">
                    <i class="me-2" :class="`${subitem.icon}`"></i>
                    {{ subitem.label }}
                  </router-link>
                </div>
              </template>
              <template v-else>
                <router-link :to="`${menu.link}`" class="nav-link dropdown-toggle arrow-none">
                  <i class="me-2" :class="`${menu.icon}`"></i>
                  {{ menu.label }}
                </router-link>
              </template>
            </li>
            <!-- Menu data -->
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>