<template>
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-sm-6 text-sm-start text-center">{{ new Date().getFullYear() }} <strong class="fw-medium">© ECU.</strong></div>
          <div class="col-sm-6">
            <div class="text-sm-end text-center">Design & Develop by <strong class="fw-medium">MISPL</strong></div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end footer -->
  </template>
  